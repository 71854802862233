import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const DiamondIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
    >
      <path
        d="M12 21.36c-.5-.36-.79-.71-1.05-1.1-.34-.5-.85-.88-1.23-1.34-.41-.5-.75-1.06-1.2-1.53-.54-.55-1-1.14-1.48-1.72-.61-.75-1.32-1.41-1.93-2.18-.59-.75-1.18-1.49-1.74-2.26C2.71 10.3 1.54 9.35 1 8.36c.5-1 1.27-1.82 1.98-2.66.5-.57.85-1.25 1.35-1.8.43-.48 1.24-1.64 1.95-1.64.41 0 .91-.06 1.38-.16.87-.17 1.83-.07 2.71-.07 2.3 0 6.13.47 6.63.47s1.27.6 1.5 1c.39.65.59 1.1 1.1 1.66.65.73.76 1.11 1.4 1.84.26.3.82.77 1 1 .18.23.5.64.5 1M12 21.36c0 .14-.23-1.05-.3-1.2-.18-.35-.23-.77-.34-1.15-.3-1.04-.88-2.05-1.35-3.02-.33-.68-.53-1.42-.88-2.08-.35-.65-.67-1.3-.97-1.97-.5-1.08-1.27-2.1-1.27-3.33.25-.08.4-.5.54-.7.4-.61.97-1.09 1.45-1.63.49-.56.9-1.13 1.34-1.73.33-.44.64-.88 1-1.3.15-.18.52-.44.57-.69m.21 18.8c0-.36.72-1.71.85-2.34.26-1.2.83-2.27 1.19-3.44.2-.64.5-1.27.73-1.9.18-.5.38-1 .61-1.48.55-1.11 1.3-2.32 1.3-3.6M12 21.37c.51-.34.36-.31.5-.44.26-.23.46-.5.69-.75.3-.3.6-.63.86-.98.5-.66 1.13-1.2 1.68-1.82.3-.35.54-.75.84-1.1.05-.07.23-.17.25-.24.05-.18.3-.2.42-.31.11-.1.2-.23.3-.34.45-.58 1.05-1 1.51-1.58.15-.18.28-.38.42-.56.22-.3.5-.52.72-.8.23-.3.44-.6.7-.87a.84.84 0 0 0 .16-.21c.09-.17.24-.36.37-.5.09-.08.22-.13.3-.22.15-.14.23-.34.36-.5.16-.19.42-1 .42-1.14m0 0-2.02-.4c-1.16 0-2.3-.14-3.46-.14-1.11 0-2.23-.16-3.35-.16-.97 0-1.88.15-2.84.25-.89.09-1.72.21-2.6.21-.93 0-1.86-.05-2.78-.08-1.46-.03-2.9-.15-4.38-.15m10.8-6.35c1.15 1.16 1.9 2.66 3.04 3.84.62.66 1.14 1.64 1.77 2.28"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

DiamondIcon.propTypes = propTypes;
DiamondIcon.defaultProps = defaultProps;

export default React.memo(DiamondIcon);
