function cartTotal(cartItems) {
  if (!cartItems) {
    return 0;
  }

  const keys = Object.keys(cartItems);

  return keys.reduce((total, key) => {
    return total + cartItems[key];
  }, 0);
}

export default cartTotal;
