function cartSummary(cartItems, products) {
  if (!cartItems) {
    return 0;
  }

  const keys = Object.keys(cartItems).filter((key) => {
    return !!products[key];
  });

  return keys.reduce((total, key) => {
    return total + (cartItems[key] * products[key].price);
  }, 0);
}

export default cartSummary;
