import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react';

import Button from '../Button';

import useOutsideClick from '../../hooks/useOutsideClick';

import { useLayoutActions, useLayoutState } from '../../layout/Context';

const overlayVariants = {
  enter: () => ({
    dislay: 'none',
    opacity: 0,
  }),
  idle: () => ({
    dislay: 'block',
    opacity: 1,
  }),
  exit: () => ({
    dislay: 'none',
    opacity: 0,
  }),
}

const OrderSuccessModal = () => {
  const modalRef = useRef();

  const { isVisibleOrderSuccessMessage } = useLayoutState();
  const { hideOrderSuccessMessage } = useLayoutActions();

  useOutsideClick(modalRef, hideOrderSuccessMessage, true);

  return (
    <AnimatePresence>
      {isVisibleOrderSuccessMessage && (
        <motion.div
          variants={overlayVariants}
          exit="exit"
          animate="idle"
          initial="enter"
          role="presentation"
          className="bg-black bg-opacity-30 fixed flex items-center justify-center inset-0 px-4"
        >
          <div
            ref={modalRef}
            exit="exit"
            animate="idle"
            initial="enter"
            className="bg-white relative z-20 p-8 rounded max-w-[32rem]"
          >
            <p className="typo-h3">
              Дякуємо за замовлення!
            </p>
            <p className="typo-blockquote mt-4">
              Ваше замовлення успішно відправлено на обробку.
            </p>
            <div className="mt-8">
              <Button onClick={hideOrderSuccessMessage} variant="primary" size="md">
                Продовжити
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OrderSuccessModal;
