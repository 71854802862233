/*
Component: QuantityControl
@quantity: number - current quantity.
@upLimit: number - compare quantity property.
@downLimit: number - compare quantity property. (default: 0)
@onUpdateQuantity(i): function - callback to update quantity if it is valid to update.
*/
import React from 'react';

import Button from '../Button';

import PropTypes from '../../utils/PropTypes';

const QuantityControl = ({ quantity, upLimit, downLimit, onUpdateQuantity }) => {
  const update = (i) => {
    const newQuantity = quantity + i;

    // validate new quantity value
    if (newQuantity < downLimit || newQuantity > upLimit) {
      return false;
    }

    onUpdateQuantity(i);
  }

  return (
    <span className="inline-flex items-center typo-small">
      <Button onClick={() => update(-1)}>
        {'–'}
      </Button>
      <span className="px-4">
        {quantity}
      </span>
      <Button onClick={() => update(1)}>
        {'+'}
      </Button>
    </span>
  );
};


QuantityControl.propTypes = {
  quantity: PropTypes.number,
  upLimit: PropTypes.number.isRequired,
  downLimit: PropTypes.number,
  onUpdateQuantity: PropTypes.func.isRequired,
};

QuantityControl.defaultProps = {
  quantity: 0,
  downLimit: 0,
};

export default QuantityControl;
