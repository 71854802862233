import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const CloseIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
    >
      <path
        d="M3 3.24c.58.06 1.38.84 1.82 1.17.93.67 1.87 1.36 2.75 2.1.98.82 1.87 1.7 2.82 2.54 1.2 1.07 2.43 2.06 3.5 3.27 1.06 1.18 2.17 2.26 3.28 3.4.4.4.82 1.06 1.09 1.57.28.53.78 1 .97 1.57.25.77 1.32 1.32 1.7 2.06M20.55 3c-.05.23-.28.4-.44.56-.57.57-1.13 1.13-1.67 1.73-.63.7-1.15 1.65-1.9 2.25a13.3 13.3 0 0 0-2.42 2.79 31.65 31.65 0 0 1-2.8 3.3h0c-.67.72-1.36 1.4-2.06 2.11-1.09 1.09-1.72 2.63-2.94 3.6-.65.53-1.87 1.08-2.24 1.82"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

CloseIcon.propTypes = propTypes;
CloseIcon.defaultProps = defaultProps;

export default React.memo(CloseIcon);
