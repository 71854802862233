import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const EmailIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
    >
      <path
        d="M15.83 9.13c0-.54-.28-.81-.48-1.27a2.5 2.5 0 0 0-1.04-1c-.26-.1-.43-.27-.7-.34-.26-.05-.5-.14-.76-.2-.42-.09-1.46-.1-1.84.1a5.57 5.57 0 0 0-2.5 2.8c-.28.77-.5 1.5-.7 2.3-.12.5-.24 1-.26 1.52-.02.63-.22 1.4 0 2.02.32.9.36 1.84 1.3 2.3.71.36 1.55.59 2.34.3 1.36-.48 1.83-1.93 2.62-2.96.49-.62.84-1.37 1.27-2.03.44-.7.69-1.54.98-2.31.14-.35.28-.69.44-1.03.13-.3.14-.66.27-.95.17-.4.11-.88.29-1.24m-.14.2c0 .82-.54 1.48-.8 2.21-.17.47-.18.96-.24 1.44-.08.67-.19 1.35-.19 2.02 0 1.08-.03 2.16 0 3.23.02.76.53 1.35 1.3 1.43 1.12.13 2.27-.42 3.22-.99a5.82 5.82 0 0 0 2.32-3.07c.65-1.9.62-3.97.62-5.95a4.1 4.1 0 0 0-.9-2.42c-.53-.54-.99-1.34-1.72-1.65-.58-.25-1.07-.66-1.62-.9-1-.45-2.04-.95-3.15-.95-1.34 0-2.66-.2-4-.2-.68 0-1.35-.05-1.98.24-.51.23-1.09.42-1.63.6-.6.19-1.13.45-1.7.74-.38.2-.82.66-1.14.96-.26.25-.6.4-.85.66-.29.28-.64.47-.93.75-.24.23-.38.5-.57.76-.7.97-1.1 2.16-1.46 3.29a9.92 9.92 0 0 0 .82 7.8 8.26 8.26 0 0 0 3.6 3.21c1.15.58 2.27 1.33 3.55 1.63.47.1.97.17 1.45.27.33.07.68.27 1.01.29.56.03 1.13.07 1.71.07 1.33 0 2.7.02 3.9-.65.8-.46 1.5-1.04 2.4-1.34"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

EmailIcon.propTypes = propTypes;
EmailIcon.defaultProps = defaultProps;

export default React.memo(EmailIcon);
