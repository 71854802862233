import { useStaticQuery, graphql } from 'gatsby';

const useCartProductsData = (scope) => {
  const data = useStaticQuery(graphql`
    query {
      products: allProduct {
        nodes {
          title
          sku
          path
          price
          quantity
          images {
            title
            src {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  `);

  if (data.errors) {
    console.error('Build failed while running useCartProductsData GraphQL query.');
    return [];
  }

  const productsMap = {};

  data.products.nodes.forEach((product) => {
    productsMap[product.sku] = product;
  });

  return productsMap;
};

export default useCartProductsData;
