import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import PropTypes from '../utils/PropTypes';

const LayoutStateContext = createContext();
export const LayoutDispatchContext = createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case 'openCart': {
      return { ...state, cartOpen: true };
    }
    case 'closeCart': {
      return { ...state, cartOpen: false };
    }
    case 'showNavigation': {
      return { ...state, isVisibleNavigation: true };
    }
    case 'hideNavigation': {
      return { ...state, isVisibleNavigation: false };
    }
    case 'showOrderSuccessMessage': {
      return { ...state, isVisibleOrderSuccessMessage: true };
    }
    case 'hideOrderSuccessMessage': {
      return { ...state, isVisibleOrderSuccessMessage: false };
    }
    case 'changeScreenSize': {
      return {
        ...state,
        cartOpen: false,
        isVisibleOrderSuccessMessage: false,
        isSmallScreen: action.payload.isSmallScreen,
        isMediumScreen: action.payload.isMediumScreen,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const useLayoutState = () => {
  const state = useContext(LayoutStateContext);

  if (state === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }

  return state;
};

const useLayoutActions = () => {
  const dispatch = useContext(LayoutDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useLayoutActions must be used within a LayoutProvider');
  }

  return {
    openCart: () => dispatch({ type: 'openCart' }),
    closeCart: () => dispatch({ type: 'closeCart' }),
    showOrderSuccessMessage: () => dispatch({ type: 'showOrderSuccessMessage' }),
    hideOrderSuccessMessage: () => dispatch({ type: 'hideOrderSuccessMessage' }),
  };
};

const LayoutProvider = ({ location, children }) => {
  const [state, dispatch] = useReducer(layoutReducer, {
    cartOpen: false,
    isVisibleNavigation: true,
    isSmallScreen: false,
    isMediumScreen: false,
    isVisibleOrderSuccessMessage: false,
  });
  const isSmallScreen = useMediaQuery('(max-width: 576px)');
  const isMediumScreen = useMediaQuery('(max-width: 1023px)');

  useEffect(() => {
    dispatch({ type: 'changeScreenSize', payload: { isSmallScreen, isMediumScreen } });
  }, [isSmallScreen, isMediumScreen]);

  useEffect(() => {
    dispatch({ type: 'closeCart' });

    if (location.pathname === '/checkout/') {
      dispatch({ type: 'hideNavigation' });
    } else {
      dispatch({ type: 'showNavigation' });
    }
  }, [location.pathname]);

  return (
    <LayoutDispatchContext.Provider value={dispatch}>
      <LayoutStateContext.Provider value={state}>
        {children}
      </LayoutStateContext.Provider>
    </LayoutDispatchContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node,
  location: PropTypes.location.isRequired,
};
LayoutProvider.defaultProps = {
  children: null,
};

const LayoutConsumer = ({ children }) => {
  const state = useLayoutState();
  const actions = useLayoutActions();

  return children({ ...state, ...actions });
};

LayoutConsumer.propTypes = { children: PropTypes.func.isRequired };

export {
  LayoutConsumer,
  LayoutProvider,
  useLayoutActions,
  useLayoutState,
};
