import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const ShippingBoxIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
    >
      <path
        d="M23 7a7.56 7.56 0 0 0-1.4-1.07c-.28-.12-.54-.47-.84-.5-.3-.04-.72-.23-1.02-.34-.4-.14-.86-.42-1.24-.59-.96-.43-1.05-.52-2-1-.37-.18-1.3-.9-1.66-1.09-.28-.14-.54-.37-.84-.41-.44 0-.89.32-1.28.52-.4.2-.83.41-1.23.63-.5.29-1.04.53-1.49.85m13 3c0 1.5.18 2.85.18 4.2 0 1.64-.18 2.16-.18 3.3V17c-.53.38-1.22.93-1.74 1.28-.42.3-.8.67-1.26.88-.46.2-.94.43-1.36.7-.41.26-.86.5-1.31.7-.35.17-.73.4-1.1.5-.3.09-.73.31-.96.5-.23.17-.99.44-1.27.44m9-15c-1 .5-2.27 1.3-3 1.5a8.4 8.4 0 0 0-2.5 1.5c-.74.74-.5.5-1.74.94-.81.29-1.13.56-1.76.56M14 22c-.64 0-1-.47-1.6-.68-.3-.11-.72-.28-.95-.51-.35-.35-.93-.5-1.35-.73-.35-.2-.68-.36-1.07-.52-.86-.35-1.19-.66-2.03-1.06-.2-.1-.5 0-1-.5-.32-.32-.9-.45-1-.5 0-2.3-.28-5.21 0-7.5.13-1.06 0-1.93 0-3m9 15c0-.62-.02-1.9 0-2.5.02-.69 0-1.81 0-2.5v-5.5M5 7c.17-.02.87-.4 1-.5.28-.22.73-.28 1-.5.3-.24.6-.3.92-.5.42-.27.88-.51 1.26-.84.28-.24.51-.44.82-.66M5 7c.43.29 2.05.72 2.5 1 .43.27 1.01.36 1.44.64.44.28.79.65 1.21.95.66.46 1.37.87 2.06 1.26.57.31 1.14.65 1.79.65M10 4c.36 0 1 .63 1.3.77.51.22.92.64 1.42.89.52.26.97.61 1.47.9.66.37 1.3.75 1.9 1.22.42.33.78.71 1.2 1.04.27.2.59.34.71.71.26.76.13 3.2.13 2.4M1.4 10.39h1.24M.81 12.51h1.9M1.7 14.34h.88m14.25 1.46v.66m.87-1.24v.87m1.1-1.39c0 .3-.09.78.07 1.03m1.1-1.83V15"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

ShippingBoxIcon.propTypes = propTypes;
ShippingBoxIcon.defaultProps = defaultProps;

export default React.memo(ShippingBoxIcon);
