import * as React from 'react';
import Link from '../components/Link';

const Footer = () => {
  return (
    <footer className="container-lg">
      <div className="border-t border-gray-200 py-4 flex justify-between">
        <small className="typo-small">Not only pot &copy;</small>
        <small className="typo-small">
          Розробник:
          {' '}
          <Link to="https://pratsko.dev/" className="no-underline">
            pratsko.dev
          </Link>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
