import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';

import CartModal from '../components/CartModal';
import OrderSuccessModal from '../components/OrderSuccessModal';

import { LayoutProvider, LayoutConsumer } from './Context';
import { CartProvider } from './CartContext';
import Header from './Header';
import Footer from './Footer';

const contentInnerVariants = {
  enter: () => ({
    opacity: 0,
  }),
  idle: () => ({
    opacity: 1,
    transition: { duration: 1 },
  }),
  exit: () => ({
    opacity: 0,
    transition: { duration: 0 },
  }),
};

const Layout = ({ children, location }) => {
  return (
    <LayoutProvider location={location}>
      <CartProvider>
        <LayoutConsumer>
          {({ isVisibleNavigation }) => (
            <>
              {isVisibleNavigation && (
                <Header />
              )}
              <div className="bg-white overflow-hidden relative">
                <AnimatePresence initial={false}>
                  <motion.div
                    key={location.pathname}
                    variants={contentInnerVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                  >
                    <main>
                      {children}
                    </main>
                  </motion.div>
                </AnimatePresence>
              </div>
              <OrderSuccessModal />
              <CartModal />
              <Footer />
            </>
          )}
        </LayoutConsumer>
      </CartProvider>
    </LayoutProvider>
  );
};

export default Layout;
