import classnames from 'classnames'
import React, { useState } from 'react'
import logoIconSrc from '../../images/logo-icon.svg';
import randomIndex from '../../utils/randomIndex';

const colorClassNames = [
  'bg-gray-50',
  'bg-gray-100',
  'bg-indigo-100',
  'bg-yellow-100',
  'bg-orange-100',
  'bg-blue-100',
]

const PotIconLogo = ({ className }) => {
  const [bgClassName, setBgClassName] = useState(0)

  const updateColor = () => {
    const newColorIndex = randomIndex(colorClassNames.length)

    setBgClassName(colorClassNames[newColorIndex])
  }

  return (
    <div className="group relative h-12 w-12" onMouseEnter={updateColor}>
      <div
        className={classnames(
          'opacity-0 group-hover:opacity-100 group-hover:scale-110 duration-300 delay-75 h-[70%] -bottom-0.5 w-full absolute rounded',
          bgClassName,
        )}
      />
      <img src={logoIconSrc} alt="Notonlypot logo icon" className={classnames('relative', className)} />
    </div>
  );
};

export default PotIconLogo;
