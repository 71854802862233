import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const CartIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
    >
      <path
        d="M2 3.48c.03-.1.33-.15.42-.17.67-.19 1.41-.17 2.1-.21.83-.05 1.7-.16 2.52-.06.23.03.78.08.86.37.28 1.03.3 2.15.34 3.21.07 1.51.06 3 .23 4.5.07.61.12 1.22.21 1.82.04.2.06.41.12.61.02.07.05.24.1.3.05.04.24.05.29.06.42.06.85.1 1.27.12 1.56.08 3.12.05 4.67.06.8 0 1.62 0 2.41.08.4.04.87.23 1.26.08.37-.15.62-.62.78-.95.64-1.29.9-2.76 1.2-4.16.2-.92.4-1.86.96-2.64.06-.1.4-.36.19-.36-1.04 0-2.08-.03-3.12-.04-1.55-.02-3.08-.03-4.61-.24-.84-.1-1.79-.25-2.62-.05-.35.1-.92.23-1.2.48-.16.13-.06.48-.03.63.16.84.39 1.66.5 2.5.13.92.1 1.85.18 2.77.09 1.2.39 2.34.58 3.51.11.66.14 1.32.15 1.98.01.6.05 1.2.03 1.8-.01.35-.1.63-.27.93-.16.25-.34.58-.59.74-.76.51-1.41-.52-1.44-1.17-.02-.36.1-.66.45-.8.64-.25 1.38-.29 2.05-.4a8.14 8.14 0 0 1 3.37.01c.33.1.75.22.97.51.25.34.26.88.27 1.3 0 .24 0 .58-.18.78-.2.23-.7.15-.95.1a2.3 2.3 0 0 1-1.44-.77c-.32-.41-.36-.9.1-1.22.59-.42 1.38-.58 2.07-.75.5-.11.99-.22 1.48-.3.38-.06.39.3.44.6"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

CartIcon.propTypes = propTypes;
CartIcon.defaultProps = defaultProps;

export default React.memo(CartIcon);
