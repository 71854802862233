import { AnimatePresence, motion } from 'framer-motion';
import React, { Fragment, useRef } from 'react';

import Button from '../Button';
import CartItemCard from '../CartItemCard';
import { CloseIcon } from '../icons';

import useOutsideClick from '../../hooks/useOutsideClick';
import useCartProductsData from '../../hooks/useCartProductsData';

import { useLayoutActions, useLayoutState } from '../../layout/Context';
import { useCartState } from '../../layout/CartContext';

import cartTotal from '../../utils/cartTotal';
import cartSummary from '../../utils/cartSummary';
import toPrice from '../../utils/toPrice';

const overlayVariants = {
  enter: () => ({
    dislay: 'none',
    opacity: 0,
  }),
  idle: () => ({
    dislay: 'block',
    opacity: 1,
  }),
  exit: () => ({
    dislay: 'none',
    opacity: 0,
  }),
}

const sidebarVariants = {
  enter: () => ({
    x: '100%',
    opacity: 0,
    transition: { ease: 'linear' }
  }),
  idle: () => ({
    x: '0%',
    opacity: 1,
    transition: { ease: 'linear', duration: 0.3 }
  }),
  exit: () => ({
    x: '100%',
    opacity: 0,
    transition: { ease: 'linear', duration: 0.3 }
  }),
};

const CartModal = () => {
  const sidebarRef = useRef();

  const { cartOpen } = useLayoutState();
  const { closeCart } = useLayoutActions();
  const { cartItems } = useCartState();

  const products = useCartProductsData();
  const cartItemKeys = Object.keys(cartItems);

  useOutsideClick(sidebarRef, closeCart, true);

  return (
    <AnimatePresence>
      {cartOpen && (
        <>
          <motion.div
            variants={overlayVariants}
            exit="exit"
            animate="idle"
            initial="enter"
            role="presentation"
            className="bg-black bg-opacity-30 fixed inset-0 z-20"
          />
          <motion.div
            ref={sidebarRef}
            variants={sidebarVariants}
            exit="exit"
            animate="idle"
            initial="enter"
            className="bg-white fixed flex flex-col w-full sm:w-[30rem] right-0 top-0 bottom-0 z-20"
          >
            <div className="h-20 flex flex-shrink-0 items-center justify-between px-4">
              <h2 className="typo-h3">
                Що в кошику?
              </h2>
              <div>
                <Button onClick={closeCart} className="typo-body">
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <div className="p-4 flex-auto overflow-y-auto">
              {!cartTotal(cartItems) && (
                <>
                  <p className="typo-body">
                    Нічого :(
                  </p>
                  <div className="mt-4">
                    <Button onClick={closeCart} variant="primary" size="sm">
                      Продовжити покупки
                    </Button>
                  </div>
                </>
              )}
              {!!cartTotal(cartItems) && (
                <div className="flex flex-col gap-y-4">
                  {cartItemKeys.map((key) => (
                    <Fragment key={key}>
                      {!!cartItems[key] && (
                        <CartItemCard
                          card={products[key]}
                          quantity={cartItems[key]}
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
            </div>
            {!!cartTotal(cartItems) && (
              <div className="bg-gray-50 px-4 py-4 mt-auto">
                <div className="flex justify-between">
                  <span className="typo-body">
                    Загальна сума
                  </span>
                  <span className="typo-body">
                    {toPrice(cartSummary(cartItems, products))}
                  </span>
                </div>
                <div className="mt-4">
                  <Button to="/checkout/" variant="primary" size="md" className="w-full justify-center">
                    Оформити замовлення
                  </Button>
                </div>
              </div>
            )}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default CartModal;
