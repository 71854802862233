import { GatsbyImage as Image, getImage } from 'gatsby-plugin-image';
import React from 'react';

import QuantityControl from '../../components/QuantityControl';

import { useCartActions } from '../../layout/CartContext';

import toPrice from '../../utils/toPrice';

import PropTypes from '../../utils/PropTypes';

const CartItemCard = ({ card, quantity, disableQuantityUpdate }) => {
  const { updateCartItem } = useCartActions();

  if (!card) {
    return null
  }

  return (
    <div className="flex">
      <div className="flex-shrink-0 bg-gray-50 rounded">
        <Image
          image={getImage(card.images[0].src)}
          alt={`Зображення ${card.images[0].title}`}
          className="h-24 w-24"
          imgClassName="rounded"
          objectFit="cover"
        />
      </div>
      <div className="pl-4">
        <p className="typo-h5">
          {card.title}
        </p>
        <p className="typo-small">
          {toPrice(card.price)}
        </p>
        <div className="mt-4">
          {!disableQuantityUpdate && (
            <div className="bg-black text-white rounded py-0.5 px-3 inline-block">
              <QuantityControl
                quantity={quantity}
                upLimit={card.quantity}
                onUpdateQuantity={(quantity) => { updateCartItem(card.sku, quantity) }}
              />
            </div>
          )}
        </div>
      </div>
      {!!quantity && (
        <div className="ml-auto text-right">
          {disableQuantityUpdate && (
            <small className="typo-small">
              {`Кількість: ${quantity}`}
            </small>
          )}
          <small className="typo-body block">
            {toPrice(card.price * quantity)}
          </small>
        </div>
      )}
    </div>
  );
};

CartItemCard.propTypes = {
  card: PropTypes.shape({}).isRequired,
  quantity: PropTypes.number.isRequired,
  disableQuantityUpdate: PropTypes.bool,
}

export default CartItemCard;
