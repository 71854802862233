import { motion } from 'framer-motion';
import React from 'react';
import Button from '../components/Button';
import CartIcon from '../components/icons/CartIcon';
import Link from '../components/Link';
import PotIconLogo from '../components/PotIconLogo';

import { useLayoutActions } from './Context';
import { useCartState } from './CartContext';

import cartTotal from '../utils/cartTotal';

const cartTotalVariants = {
  active: {
    x: '-100%',
  },
  inactive: {
    x: '0%',
  },
};

const Header = () => {
  const { openCart } = useLayoutActions();
  const { cartItems } = useCartState();

  return (
    <>
      <header className="hover:bg-white duration-300 fixed w-full left-0 top-0 right-0 z-20">
        <div className="container-lg flex items-center justify-between h-20">
          <Link to="/" className="flex items-center no-underline">
            <PotIconLogo className="h-12 w-12" />
          </Link>
          <nav className="py-2 rounded flex gap-x-8">
            <Link to="/shop/" className="typo-body no-underline">
              Каталог
            </Link>
            <Link to="/blog/" className="typo-body no-underline">
              Блог
            </Link>
            <Button onClick={openCart} className="inline-flex items-center typo-body no-underline">
              <span className="inline-flex flex-nowrap h-6 w-6 overflow-hidden">
                <motion.span
                  className="h-6 w-6 flex-shrink-0"
                  variants={cartTotalVariants}
                  initial={false}
                  animate={cartTotal(cartItems) ? 'active' : 'inactive'}
                >
                  <CartIcon className="h-6 w-6" />
                </motion.span>
                <motion.span
                  className="typo-small bg-black text-white rounded-full h-6 w-6 flex-shrink-0"
                  variants={cartTotalVariants}
                  initial={false}
                  animate={cartTotal(cartItems) ? 'active' : 'inactive'}
                >
                  {cartTotal(cartItems)}
                </motion.span>
              </span>
              <span className="ml-2">
                Кошик
              </span>
            </Button>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
