import React from 'react';
import PropTypes from '../../../utils/PropTypes';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: 'h-6 w-6',
};

const InstagramIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
    >
      <path
        d="M18.28 5.76c-.3 0-.66.17-.7.51m0 0v.07c0 .43.52.57.87.57.42 0 .12-.6-.15-.7-.23-.07-.53-.07-.72.06Zm0 0a.4.4 0 0 0-.1.1M2.05 5.85c0 2.54-.11 5.06 0 7.6.06 1.3 0 2.24.2 3.56 0 .69.22 1.39.5 2 .14.3 0 .5.56 1.5.25.44.95 1.29 1.44 1.5.77.33 1.68.28 2.5.42.62.1 1.67-.01 2.3 0 .8.01 1.77-.06 2.57 0 1.77.13 3.87 0 5.64 0 .62 0 1.2.03 1.7-.37.53-.42 1.16-.7 1.65-1.14 1.01-.9.71-2.7.71-3.87v-2.46c0-.94.26-1.84.27-2.78V9.02c-.01-1.26-.36-2.45-.49-3.7-.08-.76-.09-1.52-.76-2a4.67 4.67 0 0 0-1.82-.78 20.36 20.36 0 0 0-5.93-.5c-1.1.07-2.19.26-3.3.26h-.06c-1.03 0-2.06 0-3.09.18-.77.13-1.6.24-2.28.66-.7.42-1.4 1-2 1.6-.36.36-.3.58-.3 1.1Zm12.7 2.66a4.42 4.42 0 0 0-3.03-1.06c-1.38 0-2.29.66-3.15 1.73-.28.35-.88.98-.93 1.44-.05.58 0 1.2 0 1.8.02 1.57.83 3.26 2.32 3.9 1.59.66 3.14.11 4.64-.5.89-.36 1.96-1.27 2.08-2.26.13-1.06.25-2.16-.35-3.1a8.82 8.82 0 0 0-1.58-1.95Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

InstagramIcon.propTypes = propTypes;
InstagramIcon.defaultProps = defaultProps;

export default React.memo(InstagramIcon);
